import {useState, useEffect} from 'react'
import {Outlet, useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import background from '../images/hero-background.jpg'

import {FaHamburger} from 'react-icons/fa'

export default function AppRoutes() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  const location = useLocation()

  useEffect(() => setHamburgerOpen(false), [location])

  return (
    <>
      <div className='flex flex-col h-screen shadow-lg'>
        <header className='flex h-14 flex-grow-0 shadow-lg px-3 py-2 items-center justify-between bg-theme-blue text-white'>
          <Link to='/' className='font-semibold text-xl'>
            Avy Obs Notifier
          </Link>
          <button
            className='sm:hidden text-3xl'
            onClick={() => setHamburgerOpen((prevState) => !prevState)}
            type='button'
          >
            <FaHamburger />
          </button>
          {hamburgerOpen && (
            <div
              className='absolute inset-0 z-40'
              onClick={() => setHamburgerOpen(false)}
            ></div>
          )}
          <div
            className={`sm:flex ${
              hamburgerOpen
                ? 'flex absolute top-0 left-0 z-50 w-full mt-14 flex-col bg-theme-blue text-center pb-1'
                : 'hidden'
            }`}
          >
            <Link to='about'>
              <div className='px-3 py-2'>About</div>
            </Link>
            <Link to='feedback'>
              <button
                className='px-3 py-2 bg-theme-blue-dark text-white rounded-lg ml-4 hover:bg-theme-blue'
                type='button'
              >
                Leave feedback
              </button>
            </Link>
          </div>
        </header>
        <div className='relative flex-grow'>
          <div className='absolute inset-0 z-10 flex p-3 md:items-center'>
            <Outlet />
          </div>
          <img
            className='absolute inset-0 z-0 h-full w-full object-cover object-left-top'
            src={background}
            alt='background of skier in the wasatch mountains'
          />
        </div>
      </div>
      <footer className='px-12 py-6 flex text-gray-500 flex-col sm:flex-row'>
        <div className='flex flex-col flex-grow-0 mb-4 sm:mb-0'>
          <h2 className='text-lg mb-2 font-semibold'>
            Avalanche {'&'} Snow Observations Notifier
          </h2>
          <Link to='about'>
            <p>About</p>
          </Link>
          <Link to='feedback'>
            <p>Leave feedback</p>
          </Link>
        </div>
        <div className='flex flex-col items-end justify-end flex-grow'>
          <p>
            Designed, built, and maintained by{' '}
            <a
              className='text-yellow-600 font-semibold hover:underline'
              href='https://www.kellenbusbysoftware.com'
              target='_blank'
              rel='noreferrer noopener'
            >
              Kellen Busby Software
            </a>
          </p>
        </div>
      </footer>
    </>
  )
}
