import {Link} from 'react-router-dom'
import UnsubscribeInstructions from '../components/UnsubscribeInstructions'
import Container from '../components/Container'

export default function About() {
  return (
    <Container className='space-y-4'>
      <h1 className='font-bold text-2xl'>About</h1>
      <p>
        Avy Obs Notifier is a simple service that checks the Utah Avalanche
        Center’s{' '}
        <a
          href='https://utahavalanchecenter.org/observations'
          target='_blank'
          rel='noreferrer noopener'
        >
          Observations page
        </a>{' '}
        for new entries. When a new observation is posted you'll get an email
        and/or sms (text message) notification with a link to the observation.
      </p>
      <p>
        We are not affiliated with the UAC in any way. In the future we will
        expand to additional avalanche centers and other observation sources if
        users show interest.
      </p>
      <p>
        This project is in its very early stages so keep an eye out for new
        functionality frequently. We’ll soon have a way to sign up for updates.
        But for now please <Link to='/feedback'>leave feedback</Link>.
      </p>
      <p>
        This wouldn’t work without the Utah Avalanche Center’s Observations
        page. Learn about how you can support them on{' '}
        <a
          href='https://utahavalanchecenter.org/'
          target='_blank'
          rel='noreferrer noopener'
        >
          their website
        </a>
        .
      </p>
      <details className='cursor-pointer'>
        <summary>How to Unsubscribe from Notifications</summary>
        <div className='mt-4'>
          <UnsubscribeInstructions />
        </div>
      </details>
    </Container>
  )
}
