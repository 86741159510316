import axios from 'axios'
import {parsePhoneNumber} from 'libphonenumber-js'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export async function subscribe({email, sms}) {
  const url = `${API_BASE_URL}/subscription/subscribe`

  try {
    const response = await axios.post(
      url,
      {
        email: email ? email : undefined,
        sms: sms
          ? parsePhoneNumber(sms, 'US').formatInternational()
          : undefined,
      },
      {
        crossDomain: true,
      }
    )
    return response
  } catch (err) {
    throw err
  }
}

export async function addFeedback({email, feedback}) {
  const url = `${API_BASE_URL}/feedback`

  try {
    const response = await axios.post(
      url,
      {
        email,
        feedback,
      },
      {
        crossDomain: true,
      }
    )
    return response
  } catch (err) {
    throw err
  }
}
