import React, {useReducer, useState} from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import useFormValidation from '../hooks/useFormValidation'

import {validateEmailField, validateTelField} from '../helpers/validators'
import Container from '../components/Container'
import {subscribe} from '../API'

export const initialState = {
  email: '',
  sms: '',
  hasConsentedToSMSCharges: false,
}

export const UPDATE_TEXT_INPUT = 'update-text-input'
export const UPDATE_HAS_CONSENTED_TO_SMS_CHARGES =
  'update-has-consented-to-sms-charges'

export const reducer = (state, action) => {
  const {type, payload} = action

  switch (type) {
    case UPDATE_TEXT_INPUT:
      return {
        ...state,
        [payload.field]: payload.value,
      }
    case UPDATE_HAS_CONSENTED_TO_SMS_CHARGES:
      return {
        ...state,
        hasConsentedToSMSCharges: !state.hasConsentedToSMSCharges,
      }
    default:
      return state
  }
}

const RegisterForm = () => {
  const [formState, dispatchFormState] = useReducer(reducer, initialState)
  const [registering, setRegistering] = useState()
  const [error, setError] = useState()

  const [invalidFields, validatedFields, validateField, validateForm] =
    useFormValidation([
      {name: 'email', validator: validateEmailField},
      {name: 'sms', validator: validateTelField},
    ])

  const navigate = useNavigate()

  function handleChangeTextField(e) {
    const {value, name} = e.target
    dispatchFormState({
      type: UPDATE_TEXT_INPUT,
      payload: {
        field: name,
        value,
      },
    })
  }

  function handleChangeConsent() {
    dispatchFormState({
      type: UPDATE_HAS_CONSENTED_TO_SMS_CHARGES,
    })
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (formState.email && formState.sms) {
      if (!validateForm(formState, setError)) {
        return
      }
      if (!formState.hasConsentedToSMSCharges) {
        setError(
          'You must acknowledge that you may incur SMS charges from your service provider'
        )
        return
      }
    }

    if (formState.email && !formState.sms) {
      if (!validateEmailField(formState.email, setError)) {
        return
      }
    }

    if (!formState.email && formState.sms) {
      if (!validateTelField(formState.sms, setError)) {
        return
      }
      if (!formState.hasConsentedToSMSCharges) {
        setError(
          'You must acknowledge that you may incur SMS charges from your service provider'
        )
        return
      }
    }

    setRegistering(true)
    subscribe({...formState})
      .then((res) => {
        navigate('registered', {state: {formState}})
      })
      .catch((err) => {
        setError(err.message)
      })
      .finally(() => {
        setRegistering(false)
      })
  }

  return (
    <Container>
      <p className='mb-5 font-semilight text-3xl'>
        Get a notification when a new snow conditions observation is posted in
        the Wasatch.
      </p>
      <form className='flex flex-col' onSubmit={handleSubmit}>
        <div className='flex flex-col'>
          <label htmlFor='email'>Email</label>
          <input
            className={`text-black rounded-lg disabled:opacity-50 mb-4 ${
              invalidFields.includes('email') ? 'border-red-500' : ''
            }`}
            type='email'
            name='email'
            placeholder='Email'
            value={formState.email}
            onChange={handleChangeTextField}
            disabled={registering}
          />
        </div>

        <div className='flex flex-row space-x-2 justify-center items-center mb-3 mt-2'>
          <div className='border-gray-300 border-b-2 w-10'></div>
          <div className='text-gray-300'>and / or</div>
          <div className='border-gray-300 border-b-2 w-10'></div>
        </div>

        <div className='relative'>
          <div className='absolute z-20 top-8 right-0 left-0 mx-10 bg-green-400 text-center p-5 rounded-lg transform -rotate-12'>
            <p>SMS notifications coming soon.</p>
            <p className='text-xs leading-3 mt-1'>
              Unfortunately sending texts can get expensive. Would you pay for
              text notifications? Tell me, <Link to='feedback'>here.</Link>
            </p>
          </div>
          <div className='flex flex-col opacity-50'>
            <label htmlFor='sms'>Phone Number</label>
            <input
              className={`text-black rounded-lg disabled:opacity-50 mb-4 ${
                invalidFields.includes('sms') ? 'border-red-500' : ''
              }`}
              type='tel'
              name='sms'
              placeholder='Cell Phone Number'
              value={formState.sms}
              onChange={handleChangeTextField}
              disabled={registering}
            />
          </div>

          <div className='mb-4 opacity-50'>
            <div htmlFor='hasConsentedToSMSCharges'>
              <label className='checkbox cursor-pointer'>
                <input
                  className='disabled:opacity-50 text-yellow-600 rounded-md w-5 h-5 focus:ring-1 focus-visible:ring-1 focus:ring-cyan focus-visible:ring-cyan mr-1'
                  name='hasConsentedToSMSCharges'
                  onChange={handleChangeConsent}
                  value={formState.hasConsentedToSMSCharges}
                  type='checkbox'
                  disabled={registering}
                />{' '}
                I acknowledge that I may incur SMS charges from my service
                provider.
              </label>
            </div>
          </div>
        </div>

        {error && (
          <div className='bg-red-500 p-2 rounded-md text-sm mt-4 -mb-9'>
            {error}
          </div>
        )}

        <button
          className={`bg-yellow-600 py-2 px-5 rounded-lg self-end w-56 mt-14 ${
            registering ? 'animate-pulse' : ''
          }`}
          type='submit'
        >
          {registering ? 'Registering...' : 'Register for Notifications'}
        </button>
      </form>
    </Container>
  )
}

export default RegisterForm
