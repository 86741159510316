import validator from 'validator'

const validateRequired = (str) => {
  return str.toString().trim().length
}

const validateEmail = (email) => {
  return validator.isEmail(email)
}

const validateTel = (phoneNumber) => {
  return validator.isMobilePhone(phoneNumber, 'en-US')
}

const validatePassword = (password) => {
  return password.toString().trim().length >= 8
}

const validateEmailField = (email, setError) => {
  if (!validateRequired(email)) {
    setError('Email is required.')
    return false
  }
  if (!validateEmail(email)) {
    setError(`${email} is an invalid email.`)
    return false
  }
  setError(null)
  return true
}

const validatePasswordField = (password, setError) => {
  if (!validateRequired(password)) {
    setError('Password is required.')
    return false
  }
  if (!validatePassword(password)) {
    setError('Invalid password. Password must be at least 8 characters.')
    return false
  }
  setError(null)
  return true
}

const validateTelField = (phoneNumber, setError) => {
  if (!validateRequired(phoneNumber)) {
    setError('Phone Number is required.')
    return false
  }
  if (!validateTel(phoneNumber)) {
    setError('Invalid phone number.')
    return false
  }
  setError(null)
  return true
}

const validateTextField = (input, setError, validators) => {
  if (
    !validators ||
    !validators.length ||
    !validators.every((validator) => validator.validate && validator.message)
  ) {
    throw new Error('an array of validators must be passed to this function')
  }

  validators.forEach((validator) => {
    if (!validator.validate(input)) {
      setError(validator.message)
      return false
    }
  })

  return true
}

export {
  validateRequired,
  validateEmail,
  validatePassword,
  validateEmailField,
  validatePasswordField,
  validateTextField,
  validateTelField,
  validateTel,
}
