import exampleEmail from '../images/example-email.png'

export default function UnsubscribeInstructions() {
  return (
    <div>
      <h1 className='font-bold text-xl mb-4'>
        How to Unsubscribe from Email and SMS Notifications
      </h1>
      <h2 className='font-semibold text-lg mb-1'>Email</h2>
      <p>
        You can unsubscribe from email notifications by clicking on the
        unsubscribe link in any email notification you get.
      </p>
      <p>Example:</p>
      <img
        src={exampleEmail}
        alt='example email highlighting the location of the unsubscribe link'
      />
      <h2 className='font-semibold text-lg mb-1 mt-4'>SMS (Text Messages)</h2>
      <p>
        You can unsubscribe from SMS notifications by texting STOP to the same
        phone number you're receiving notifications from.
      </p>
      <p className='mt-10 text-xs'>
        In future versions of this service you will be able to log in and manage
        your subscriptions right from this app.
      </p>
    </div>
  )
}
