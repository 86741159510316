import {Routes, Route} from 'react-router-dom'

import AppRoutes from './routes/AppRoutes'
import RegisterForm from './routes/RegisterForm'
import RegistrationSuccess from './routes/RegistrationSuccess'
import About from './routes/About'
import LeaveFeedback from './routes/LeaveFeedback'

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<AppRoutes />}>
          <Route index element={<RegisterForm />} />
          <Route path='/about' element={<About />} />
          <Route path='/registered' element={<RegistrationSuccess />} />
          <Route path='/feedback' element={<LeaveFeedback />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
