import {useLocation} from 'react-router'

import Container from '../components/Container'
import UnsubscribeInstructions from '../components/UnsubscribeInstructions'

export default function RegistrationSuccess() {
  const location = useLocation()

  const formState = location.state?.formState

  return (
    <Container>
      <p className='font-light text-2xl mb-8'>
        You've been successfully registered for{' '}
        {formState.email && formState.sms
          ? 'email and sms'
          : formState.email
          ? 'email'
          : 'sms'}{' '}
        notifications!
      </p>
      {formState && formState.email && (
        <p className='font-light text-2xl mb-8'>
          Please check your email for a confirmation link to verify your email
          notitications.
        </p>
      )}
      <details className='cursor-pointer'>
        <summary>How to Unsubscribe from Notifications</summary>
        <div className='mt-4'>
          <UnsubscribeInstructions />
        </div>
      </details>
    </Container>
  )
}
