import {useState} from 'react'
import Container from '../components/Container'
import useFormValidation from '../hooks/useFormValidation'
import {
  validateEmailField,
  validateTextField,
  validateRequired,
} from '../helpers/validators'

import {addFeedback} from '../API'

export default function LeaveFeedback() {
  const [formState, setFormState] = useState({
    email: '',
    feedback: '',
  })
  const [submitting, setSubmitting] = useState()
  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const [invalidFields, validatedFields, validateField, validateForm] =
    useFormValidation([
      {name: 'email', validator: validateEmailField},
      {
        name: 'feedback',
        validator: (input, errHandler) =>
          validateTextField(input, errHandler, [
            {
              validate: validateRequired,
              message:
                'Please write something in the feedback field before submitting.',
            },
          ]),
      },
    ])

  function handleChangeInput(e) {
    const {value, name} = e.target

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (validateForm(formState, setError)) {
      setSubmitting(true)
      addFeedback({...formState})
        .then((res) => {
          setSuccess('Thanks so much for your feedback!')
        })
        .catch((err) => {
          setError(err.message)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  return (
    <Container>
      <p className='mb-5 font-semilight text-3xl'>We want to hear from you.</p>
      <p className='mb-1'>
        Any feedback is good feedback but we would love to know:
      </p>
      <ul className='text-sm list-disc list-inside mb-6 ml-3'>
        <li>What do you like about avy obs notifier?</li>
        <li>What don't you like?</li>
        <li>What features do you want added?</li>
        <li>what questions do you have about the service?</li>
      </ul>
      <form className='flex flex-col' onSubmit={handleSubmit}>
        <div className='flex flex-col'>
          <label htmlFor='email'>Email</label>
          <input
            className={`text-black rounded-lg disabled:opacity-50 mb-4 ${
              invalidFields.includes('email') ? 'border-red-500' : ''
            }`}
            type='email'
            name='email'
            placeholder='Email'
            value={formState.email}
            onChange={handleChangeInput}
            disabled={submitting}
            onBlur={() => {
              validateField('email', formState.email, (err) => {
                setError(err)
              })
            }}
          />
        </div>
        <div className='flex flex-col'>
          <label htmlFor='feedback'>Feedback</label>
          <textarea
            className={`h-40 rounded-lg text-black disabled:opacity-50 ${
              invalidFields.includes('feedback') ? 'border-red-500' : ''
            }`}
            name='feedback'
            value={formState.feedback}
            onChange={handleChangeInput}
            disabled={submitting}
            placeholder='I would love it if Avy Obs Notifier could...'
            onBlur={() => {
              validateField('feedback', formState.feedback, (err) => {
                setError(err)
              })
            }}
          ></textarea>
        </div>

        {error && (
          <div className='bg-red-500 p-2 rounded-md text-sm mt-4 -mb-9'>
            {error}
          </div>
        )}

        {success && (
          <div className='bg-green-600 p-2 rounded-md text-sm mt-4 -mb-9'>
            {success}
          </div>
        )}

        <button
          className={`bg-yellow-600 py-2 px-5 rounded-lg self-end w-56 mt-14 ${
            submitting ? 'animate-pulse' : ''
          }`}
          type='submit'
        >
          {submitting ? 'Submitting Feedback...' : 'Leave Feedback'}
        </button>
      </form>
    </Container>
  )
}
