export default function Container({children, className}) {
  return (
    <div
      className={`w-full h-full md:w-2/5 md:h-auto md:ml-8 p-6 rounded-lg shadow-xl bg-theme-blue text-white overflow-scroll max-h-full ${
        className ? className : ''
      }`}
    >
      {children}
    </div>
  )
}
