import { useState } from 'react';

function removeFrom(name, arr) {
  const idx = arr.findIndex((item) => item === name);
  if (idx === -1) return arr;
  return arr.splice(idx, 1);
}

export default function useFormValidation(fields) {
  if (
    !fields ||
    fields.length <= 0 ||
    !fields.every(
      (field) =>
        field.name && field.validator && typeof field.validator === 'function'
    )
  ) {
    throw new Error(
      'invalid fields format for this hook, each field must have a name and a validator function'
    );
  }

  const [invalidFields, setInvalidFields] = useState([]);
  const [validatedFields, setValidatedFields] = useState([]);

  function setFieldError(err, field) {
    if (err) {
      setInvalidFields((prevState) => [...prevState, field]);
      return;
    }
    setInvalidFields((prevState) => [...removeFrom(field, prevState)]);
    setValidatedFields((prevState) => [...prevState, field]);
  }

  function validateField(fieldName, fieldValue, setError) {
    const { name, validator } = fields.find(
      (field) => field.name === fieldName
    );
    const valid = validator(fieldValue, (validationErr) => {
      setFieldError(validationErr, name);
      if (setError && typeof setError === 'function') setError(validationErr);
    });
    return valid;
  }

  function validateForm(formValues, setError) {
    let shouldSubmit = true;

    fields.forEach(({ name }) => {
      const valid = validateField(name, formValues[name], setError);
      if (!valid) {
        shouldSubmit = false;
      }
    });

    return shouldSubmit;
  }

  return [invalidFields, validatedFields, validateField, validateForm];
}
